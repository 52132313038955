@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;
@use '@op/ui/src/consts/theme/colors.module' as colors;

.clap {
  position: relative;
}

.bubble {
  position: absolute;
  display: block;

  background: colors.$blackT_100;
  border-radius: 50%;
  font-size: 18px;
  line-height: 37px;
  text-align: center;
  color: colors.$whiteT_100;
  opacity: 0;
  transition: all 0.2s ease;
  $style: (
    top: pxToVw.calc-spacing(2.5) * -1,
    left: pxToVw.calc-spacing(4),
    width: 36px,
    height: 36px,
  );
  @include pxToVw.media-px-vw($style);
}

.isClapped .bubble {
  opacity: 1;
  transform: translate(0, -100%);
}

.button {
  background-color: colors.$whiteT_100;
  border-color: colors.$whiteT_100;
  box-shadow: 0 8px 16px 0 colors.$blackT_12;
  $style: (
    --buttonSPadding: 0 pxToVw.calc-spacing(4) 0 pxToVw.calc-spacing(2),
    --buttonSMinHeight: 48px,
    --buttonSIiconSize: 46px,
  );
  @include pxToVw.media-px-vw($style);
  &:hover {
    box-shadow: 0 30px 40px colors.$blackT_12;
  }
}

.buttonText,
.buttonCount {
  $style: (
    --OP-typography-body1-fontSize: 24px,
  );
  @include pxToVw.media-px-vw($style);
}

.buttonText {
  color: colors.$grayC_600;
  overflow: hidden;
}

.isActive .buttonText {
  max-width: 0;
  transition: max-width 0.2s ease;
}

.buttonCount {
  color: colors.$blackT_100;
}

.animation {
  position: absolute;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  transform: rotate(0);
  $style: (
    left: pxToVw.calc-spacing(5.25),
    width: 24px,
    height: 24px,
    margin-top: pxToVw.calc-spacing(3) * -1,
  );
  @include pxToVw.media-px-vw($style);
}

.animationItem {
  display: block;
  position: absolute;
  background: url('images/clap-triangle-colored.svg') no-repeat;
  background-position: center center;
  $style: (
    width: 15px,
    height: 12px,
    background-size: 15px 12px,
  );
  @include pxToVw.media-px-vw($style);
}

.animationBlock {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &:before {
    background-image: url('images/clap-triangle-small.svg');
    $style: (
      top: pxToVw.calc-spacing(2.75) * -1,
      right: pxToVw.calc-spacing(1.25) * -1,
      width: 6px,
      height: 11px,
    );
    @include pxToVw.media-px-vw($style);
  }
  &:after {
    background-image: url('images/clap-triangle.svg');
    $style: (
      top: pxToVw.calc-spacing(1.5) * -1,
      right: pxToVw.calc-spacing(4.5) * -1,
      width: 14px,
      height: 9px,
    );
    @include pxToVw.media-px-vw($style);
  }
  .animationItem {
    $style: (
      top: pxToVw.calc-spacing(3.5) * -1,
      right: pxToVw.calc-spacing(5) * -1,
    );
    @include pxToVw.media-px-vw($style);
  }
  &:last-of-type {
    top: auto;
    transform: rotate(-90deg);
    $style: (
      bottom: pxToVw.calc-spacing(1.25),
    );
    @include pxToVw.media-px-vw($style);
    &:before,
    &:after {
      right: auto;
      transform: scale(-1, 1);
    }
    &:before {
      $style: (
        top: pxToVw.calc-spacing(3.75) * -1,
        left: pxToVw.calc-spacing(1.25) * -1,
      );
      @include pxToVw.media-px-vw($style);
    }
    &:after {
      $style: (
        left: pxToVw.calc-spacing(4.5) * -1,
      );
      @include pxToVw.media-px-vw($style);
    }
    .animationItem {
      right: auto;
      transform: scale(-1, 1);
      $style: (
        top: pxToVw.calc-spacing(4.25) * -1,
        left: pxToVw.calc-spacing(5) * -1,
      );
      @include pxToVw.media-px-vw($style);
    }
  }
}

.isClapped {
  .animation {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.1s ease;
  }
  .animationBlock {
    &:before,
    &:after,
    .animationItem {
      opacity: 0;
    }
    &:before {
      transition: top 0.2s ease, opacity 0.2s 0.3s ease;
      $style: (
        top: pxToVw.calc-spacing(3.75) * -1,
      );
      @include pxToVw.media-px-vw($style);
    }
    &:after {
      transition: right 0.2s ease, opacity 0.2s 0.3s ease;
      $style: (
        right: pxToVw.calc-spacing(5.5) * -1,
      );
      @include pxToVw.media-px-vw($style);
    }
    .animationItem {
      transition: top 0.2s ease, right 0.2s ease, opacity 0.2s 0.3s ease;
      $style: (
        top: pxToVw.calc-spacing(4.5) * -1,
        right: pxToVw.calc-spacing(6) * -1,
      );
      @include pxToVw.media-px-vw($style);
    }
    &:last-of-type {
      &:before {
        $style: (
          top: pxToVw.calc-spacing(4.25) * -1,
        );
        @include pxToVw.media-px-vw($style);
      }
      &:after {
        right: auto;
        transition: left 0.2s ease, opacity 0.2s 0.3s ease;
        $style: (
          left: pxToVw.calc-spacing(5) * -1,
        );
        @include pxToVw.media-px-vw($style);
      }
      .animationItem {
        right: auto;
        transition: top 0.2s ease, left 0.2s ease, opacity 0.2s 0.3s ease;
        $style: (
          top: pxToVw.calc-spacing(4.75) * -1,
          left: pxToVw.calc-spacing(5.5) * -1,
        );
        @include pxToVw.media-px-vw($style);
      }
    }
  }
}
