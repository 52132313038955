@use '@op/ui/src/consts/theme/colors.module' as baseColors;
@use '@op/ui/src/consts/theme/buttonsOp/colors.module' as colors;
@use '@op/ui/src/consts/theme/buttonsOp/sizes.module' as sizes;
@use '@op/ui/src/consts/theme/buttonsOp/classNamesGlobal.module' as buttonClassNames;
@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;

@at-root {
  .body {
    --buttonBackgroundColor: inherit;
    --buttonBorderColor: inherit;
    --buttonTextColor: inherit;

    --buttonXlLineHeight: #{sizes.$XLLineHeight};
    --buttonLLineHeight: #{sizes.$LLineHeight};
    --buttonMLineHeight: #{sizes.$MLineHeight};
    --buttonSLineHeight: #{sizes.$SLineHeight};
    --buttonXSLineHeight: #{sizes.$XsLineHeight};
    --buttonXxsLineHeight: #{sizes.$XxsLineHeight};

    --buttonChipsColor: #{colors.$chips};
    --buttonChipsColorHover: #{colors.$chipsHover};
    --buttonChipsColorActive: #{colors.$chipsActive};
    --buttonChipsTextActive: #{colors.$chipsTextColor};

    --buttonIconWidth: auto;

    $style: (
      --buttonXlMinHeight: sizes.$XLMinHeight,
      --buttonXlPadding: sizes.$XLPaddingVertical sizes.$XLPaddingHorizont,
      --buttonXlFontSize: sizes.$XLFontSize,
      --buttonXlBorderRadius: sizes.$XLBorderRadius,
      --buttonXlIiconSize: sizes.$XLIconSize,
      --buttonXlIconMargin: sizes.$XLIconMargin,

      --buttonLMinHeight: sizes.$LMinHeight,
      --buttonLPadding: sizes.$LPaddingVertical sizes.$LPaddingHorizont,
      --buttonLFontSize: sizes.$LFontSize,
      --buttonLBorderRadius: sizes.$LBorderRadius,
      --buttonLIiconSize: sizes.$LIconSize,
      --buttonLIconMargin: sizes.$LIconMargin,

      --buttonMMinHeight: sizes.$MMinHeight,
      --buttonMPadding: sizes.$MPaddingVertical sizes.$MPaddingHorizont,
      --buttonMFontSize: sizes.$MFontSize,
      --buttonMBorderRadius: sizes.$MBorderRadius,
      --buttonMIiconSize: sizes.$MIconSize,
      --buttonMIconMargin: sizes.$MIconMargin,

      --buttonSMinHeight: sizes.$SMinHeight,
      --buttonSPadding: sizes.$SPaddingVertical sizes.$SPaddingHorizont,
      --buttonSFontSize: sizes.$SFontSize,
      --buttonSBorderRadius: sizes.$SBorderRadius,
      --buttonSIiconSize: sizes.$SIconSize,
      --buttonSIconMargin: sizes.$SIconMargin,

      --buttonXsMinHeight: sizes.$XsMinHeight,
      --buttonXsPadding: sizes.$XsPaddingVertical sizes.$XsPaddingHorizont,
      --buttonXsFontSize: sizes.$XsFontSize,
      --buttonXsBorderRadius: sizes.$XsBorderRadius,
      --buttonXsIiconSize: sizes.$XsIconSize,
      --buttonXsIconMargin: sizes.$XsIconMargin,

      --buttonXxsMinHeight: sizes.$XxsMinHeight,
      --buttonXxsPadding: sizes.$XxsPaddingVertical sizes.$XxsPaddingHorizont,
      --buttonXxsFontSize: sizes.$XxsFontSize,
      --buttonXxsBorderRadius: sizes.$XxsBorderRadius,
      --buttonXxsIiconSize: sizes.$XxsIconSize,
      --buttonXxsIconMargin: sizes.$XxsIconMargin,
    );
    @include pxToVw.media-px-vw($style);

    :global {
      #{buttonClassNames.$buttonBlue} {
        --buttonBackgroundColor: #{colors.$blue};
        --buttonBorderColor: #{colors.$blue};
        --buttonTextColor: #{colors.$white};
      }

      #{buttonClassNames.$buttonWhite} {
        --buttonBackgroundColor: #{colors.$white};
        --buttonBorderColor: #{colors.$white};
        --buttonTextColor: #{colors.$black};
      }

      #{buttonClassNames.$buttonBlack} {
        --buttonBackgroundColor: #{colors.$black};
        --buttonBorderColor: #{colors.$black};
        --buttonTextColor: #{colors.$white};
      }

      #{buttonClassNames.$buttonRed} {
        --buttonBackgroundColor: #{colors.$red};
        --buttonBorderColor: #{colors.$red};
        --buttonTextColor: #{colors.$white};
      }

      #{buttonClassNames.$buttonGreen} {
        --buttonBackgroundColor: #{colors.$green};
        --buttonBorderColor: #{colors.$green};
        --buttonTextColor: #{colors.$black};
      }

      #{buttonClassNames.$buttonGray} {
        --buttonBackgroundColor: #{colors.$gray};
        --buttonBorderColor: #{colors.$gray};
        --buttonTextColor: #{colors.$white};
      }

      .light-theme,
      [data-mui-color-scheme='light'] {
        #{buttonClassNames.$buttonWhite} {
          --buttonBackgroundColor: #{colors.$black};
          --buttonBorderColor: #{colors.$black};
          --buttonTextColor: #{colors.$white};
        }
        #{buttonClassNames.$buttonGreen} {
          --buttonTextColor: #{colors.$black};
        }
        --buttonChipsColor: #{baseColors.$base_lightGray};
        --buttonChipsColorHover: #{baseColors.$gray_steelC_300};
        --buttonChipsColorActive: #{baseColors.$blackT_100};
        --buttonChipsTextActive: #{baseColors.$whiteT_100};
      }
    }
  }
}
