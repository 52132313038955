@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;
@use '@op/ui/src/consts/theme/colors.module' as colors;
@use '@op/ui/src/consts/theme/typography.module' as typography;

@at-root {
  .body {
    --typographyHeroFontWeight: #{typography.$tHeroFontWeight};
    --typographyHeroLineHeight: #{typography.$tHeroLineHeight};

    --typographyH1FontWeight: #{typography.$tH1FontWeight};
    --typographyH1LineHeight: #{typography.$tH1LineHeight};

    --typographyH2FontWeight: #{typography.$tH2FontWeight};
    --typographyH2LineHeight: #{typography.$tH2LineHeight};

    --typographyH3FontWeight: #{typography.$tH3FontWeight};
    --typographyH3LineHeight: #{typography.$tH3LineHeight};

    --typographyH4FontWeight: #{typography.$tH4FontWeight};
    --typographyH4LineHeight: #{typography.$tH4LineHeight};

    --typographyH5FontWeight: #{typography.$tH5FontWeight};
    --typographyH5LineHeight: #{typography.$tH5LineHeight};

    --typographyH6FontWeight: #{typography.$tH6FontWeight};
    --typographyH6LineHeight: #{typography.$tH6LineHeight};

    --typographySubtitle1FontWeight: #{typography.$tSubtitle1FontWeight};
    --typographySubtitle1LineHeight: #{typography.$tSubtitle1LineHeight};

    --typographySubtitle2FontWeight: #{typography.$tSubtitle2FontWeight};
    --typographySubtitle2LineHeight: #{typography.$tSubtitle2LineHeight};

    --typographyBody1FontWeight: #{typography.$tBody1FontWeight};
    --typographyBody1LineHeight: #{typography.$tBody1LineHeight};

    --typographyBody2FontWeight: #{typography.$tBody2FontWeight};
    --typographyBody2LineHeight: #{typography.$tBody2LineHeight};

    --typographyLinksFontWeight: #{typography.$tLinksFontWeight};
    --typographyLinksLineHeight: #{typography.$tLinksLineHeight};

    --typographySmallFontWeight: #{typography.$tSmallFontWeight};
    --typographySmallLineHeight: #{typography.$tSmallLineHeight};

    --typographyCaptionFontWeight: #{typography.$tCaptionFontWeight};
    --typographyCaptionLineHeight: #{typography.$tCaptionLineHeight};

    --typographyButtonFontWeight: #{typography.$tButtonFontWeight};
    --typographyButtonLineHeight: #{typography.$tButtonLineHeight};

    --typographyButtonSmallFontWeight: #{typography.$tButtonSmallFontWeight};
    --typographyButtonSmallLineHeight: #{typography.$tButtonSmallLineHeight};
    $style: (
      --typographyHeroFontSize: typography.$tHeroFontSize,
      --typographyHeroLetterSpacingPositive: typography.$tHeroLetterSpacing,

      --typographyH1FontSize: typography.$tH1FontSize,
      --typographyH1LetterSpacingPositive: typography.$tH1LetterSpacing,

      --typographyH2FontSize: typography.$tH2FontSize,
      --typographyH2LetterSpacingPositive: typography.$tH2LetterSpacing,

      --typographyH3FontSize: typography.$tH3FontSize,
      --typographyH3LetterSpacingPositive: typography.$tH3LetterSpacing,

      --typographyH4FontSize: typography.$tH4FontSize,
      --typographyH4LetterSpacingPositive: typography.$tH4LetterSpacing,

      --typographyH5FontSize: typography.$tH5FontSize,
      --typographyH5LetterSpacingPositive: typography.$tH5LetterSpacing,

      --typographyH6FontSize: typography.$tH6FontSize,
      --typographyH6LetterSpacingPositive: typography.$tH6LetterSpacing,

      --typographySubtitle1FontSize: typography.$tSubtitle1FontSize,
      --typographySubtitle1LetterSpacingPositive: typography.$tSubtitle1LetterSpacing,

      --typographySubtitle2FontSize: typography.$tSubtitle2FontSize,
      --typographySubtitle2LetterSpacingPositive: typography.$tSubtitle2LetterSpacing,

      --typographyBody1FontSize: typography.$tBody1FontSize,
      --typographyBody1LetterSpacingPositive: typography.$tBody1LetterSpacing,

      --typographyBody2FontSize: typography.$tBody2FontSize,
      --typographyBody2LetterSpacingPositive: typography.$tBody2LetterSpacing,

      --typographyLinksFontSize: typography.$tLinksFontSize,
      --typographyLinksLetterSpacingPositive: typography.$tLinksLetterSpacing,

      --typographySmallFontSize: typography.$tSmallFontSize,
      --typographySmallLetterSpacingPositive: typography.$tSmallLetterSpacing,

      --typographyCaptionFontSize: typography.$tCaptionFontSize,
      --typographyCaptionLetterSpacingPositive: typography.$tCaptionLetterSpacing,

      --typographyButtonFontSize: typography.$tButtonFontSize,
      --typographyButtonLetterSpacingPositive: typography.$tButtonLetterSpacing,

      --typographyButtonSmallFontSize: typography.$tButtonSmallFontSize,
      --typographyButtonSmallLetterSpacingPositive: typography.$tButtonSmallLetterSpacing,
    );
    @include pxToVw.media-px-vw($style);
    --typographyHeroLetterSpacing: calc(var(--typographyHeroLetterSpacingPositive) * -1);
    --typographyH1LetterSpacing: calc(var(--typographyH1LetterSpacingPositive) * -1);
    --typographyH2LetterSpacing: calc(var(--typographyH2LetterSpacingPositive) * -1);
    --typographyH3LetterSpacing: calc(var(--typographyH3LetterSpacingPositive) * -1);
    --typographyH4LetterSpacing: calc(var(--typographyH4LetterSpacingPositive) * -1);
    --typographyH5LetterSpacing: calc(var(--typographyH5LetterSpacingPositive) * -1);
    --typographyH6LetterSpacing: calc(var(--typographyH6LetterSpacingPositive) * -1);
    --typographySubtitle1LetterSpacing: calc(var(--typographySubtitle1LetterSpacingPositive) * -1);
    --typographySubtitle2LetterSpacing: calc(var(--typographySubtitle2LetterSpacingPositive) * -1);
    --typographyBody1LetterSpacing: calc(var(--typographyBody1LetterSpacingPositive) * -1);
    --typographyBody2LetterSpacing: calc(var(--typographyBody2LetterSpacingPositive) * -1);
    --typographyLinksLetterSpacing: calc(var(--typographyLinksLetterSpacingPositive) * -1);
    --typographySmallLetterSpacing: calc(var(--typographySmallLetterSpacingPositive) * -1);
    --typographyCaptionLetterSpacing: calc(var(--typographyCaptionLetterSpacingPositive) * -1);
    --typographyButtonLetterSpacing: calc(var(--typographyButtonLetterSpacingPositive) * -1);
    --typographyButtonSmallLetterSpacing: calc(var(--typographyButtonSmallLetterSpacingPositive) * -1);

    @include media.media(desktop-large) {
      --typographyH1LetterSpacing: calc(var(--typographyHeroLetterSpacingPositive) * -1);
    }

    --text-color: #{colors.$whiteT_100};

    :global {
      .text-blue {
        --text-color: #{colors.$base_blue};
      }
      .text-red {
        --text-color: #{colors.$base_red};
      }
      .text-green {
        --text-color: #{colors.$toxicC_0};
      }
      .text-gray {
        --text-color: #{colors.$grayC_500};
      }
      .text-black {
        --text-color: #{colors.$blackT_100};
      }
      .text-inherit {
        color: inherit;
      }

      .light-theme,
      [data-mui-color-scheme='light'] {
        --text-color: #{colors.$blackT_100};

        .text-dark {
          --text-color: #{colors.$base_darkGray};
        }
        .text-black {
          --text-color: #{colors.$whiteT_100};
        }
        .MuiButton-fondGreen {
          .text-black {
            --text-color: #{colors.$blackT_100};
          }
        }
      }
    }
  }
}
