@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;
@use '@op/ui/src/consts/theme/colors.module' as colors;

.circleWithText {
  :root {
    --circleWithText-text-width: 100%;
  }
  display: flex;
  align-items: center;
}

.circle {
  flex: 0 0 auto;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  $style: (
    width: 40px,
    height: 40px,
    margin-right: pxToVw.calc-spacing(4),
  );
  @include pxToVw.media-px-vw($style);
  @include media.media(desktop-large) {
    width: pxToVw.px-vw-large(60px);
    height: pxToVw.px-vw-large(60px);
  }
  @include media.media(mobile) {
    width: 60px;
    height: 60px;
  }
}

.text {
  max-width: var(--circleWithText-text-width);
  color: colors.$grayC_500;
  white-space: pre-line;
  @include media.media(desktop-large) {
    font-size: pxToVw.px-vw-large(18px);
    line-height: 1.05;
  }

  @include media.media(mobile) {
    white-space: normal;
  }
}
