@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;

.cursor {
  position: absolute;
  display: block;
  opacity: 0;
  background-size: 100%;
  pointer-events: none;
  $style: (
    width: 100px,
    height: 100px,
    margin: 0 -50px,
  );
  @include pxToVw.media-px-vw($style);
  &.isActive {
    opacity: 1;
  }
  @include media.media(tablet) {
    display: none;
  }
}
