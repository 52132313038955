@use '@op/ui/src/consts/theme/buttons/buttons.module' as buttons;
@use '@op/ui/src/consts/theme/buttonsOp/colors.module' as colors;
@use '@op/ui/src/consts/theme/fonts.module' as fonts;

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1px;
  font-family: fonts.$fontBase;
  border-style: solid;
  border-width: 1px;
  background: none;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none !important;
  cursor: pointer;
  transform-origin: 50% 50% 0;
  transform: scale(1);
}

.iconBlock {
  display: inline-flex;
  overflow: hidden;
}

.buttonIcon {
  width: auto;
  height: 100%;
}

.button {
  &:hover {
    .buttonIcon[data-testid='IconArrowAngle'] {
      animation: buttons.$animationArrowAngle;
    }
    .buttonIcon[data-testid='IconArrow'] {
      animation: buttons.$animationArrow;
    }
  }
}

.fullWidth {
  width: 100%;
}

.iconStart,
.iconEnd {
}

.fond,
.icon {
  background: var(--buttonBackgroundColor);
  border-color: var(--buttonBorderColor);
  color: var(--buttonTextColor);

  .iconBlock {
    color: var(--buttonTextColor);
  }
}

.outline {
  border-color: var(--buttonBorderColor);
  color: var(--buttonBorderColor);
}

.ghost,
.iconGhost {
  color: var(--buttonBackgroundColor);
  border: none;
}

.iconGhost {
  &:hover {
    background: rgba(197, 210, 216, 0.2);
  }
}

.shadow {
  box-shadow: buttons.$shadow;
}

.Xl {
  height: var(--buttonXlMinHeight);
  padding: var(--buttonXlPadding);
  font-size: var(--buttonXlFontSize);
  border-radius: var(--buttonXlBorderRadius);
  line-height: var(--buttonXlLineHeight);
  .iconBlock {
    width: var(--buttonIconWidth);
    height: var(--buttonXlIiconSize);
  }
  .iconStart {
    margin-right: var(--buttonXlIconMargin);
  }
  .iconEnd {
    margin-left: var(--buttonXlIconMargin);
  }
}

.L {
  height: var(--buttonLMinHeight);
  padding: var(--buttonLPadding);
  font-size: var(--buttonLFontSize);
  border-radius: var(--buttonLBorderRadius);
  line-height: var(--buttonLLineHeight);
  .iconBlock {
    width: var(--buttonIconWidth);
    height: var(--buttonLIiconSize);
  }
  .iconStart {
    margin-right: var(--buttonLIconMargin);
  }
  .iconEnd {
    margin-left: var(--buttonLIconMargin);
  }
}

.M {
  height: var(--buttonMMinHeight);
  padding: var(--buttonMPadding);
  font-size: var(--buttonMFontSize);
  border-radius: var(--buttonMBorderRadius);
  line-height: var(--buttonMLineHeight);
  .iconBlock {
    width: var(--buttonIconWidth);
    height: var(--buttonMIiconSize);
  }
  .iconStart {
    margin-right: var(--buttonMIconMargin);
  }
  .iconEnd {
    margin-left: var(--buttonMIconMargin);
  }
}

.S {
  height: var(--buttonSMinHeight);
  padding: var(--buttonSPadding);
  font-size: var(--buttonSFontSize);
  border-radius: var(--buttonSBorderRadius);
  line-height: var(--buttonSLineHeight);
  .iconBlock {
    width: var(--buttonIconWidth);
    height: var(--buttonSIiconSize);
  }
  .iconStart {
    margin-right: var(--buttonSIconMargin);
  }
  .iconEnd {
    margin-left: var(--buttonSIconMargin);
  }
}

.Xs {
  height: var(--buttonXsMinHeight);
  padding: var(--buttonXsPadding);
  font-size: var(--buttonXsFontSize);
  border-radius: var(--buttonXsBorderRadius);
  line-height: var(--buttonXSLineHeight);
  .iconBlock {
    width: var(--buttonIconWidth);
    height: var(--buttonXsIiconSize);
  }
  .iconStart {
    margin-right: var(--buttonXsIconMargin);
  }
  .iconEnd {
    margin-left: var(--buttonXsIconMargin);
  }
}

.Xxs {
  height: var(--buttonXxsMinHeight);
  padding: var(--buttonXxsPadding);
  font-size: var(--buttonXxsFontSize);
  border-radius: var(--buttonXxsBorderRadius);
  line-height: var(--buttonXxsLineHeight);
  .iconBlock {
    width: var(--buttonIconWidth);
    height: var(--buttonXxsIiconSize);
  }
  .iconStart {
    margin-right: var(--buttonXxsIconMargin);
  }
  .iconEnd {
    margin-left: var(--buttonXxsIconMargin);
  }
}

.icon,
.iconGhost {
  padding: 0;
  font-size: 0;
  &.Xl {
    width: var(--buttonXlMinHeight);
    height: var(--buttonXlMinHeight);
  }
  &.L {
    width: var(--buttonLMinHeight);
    height: var(--buttonLMinHeight);
  }
  &.S {
    width: var(--buttonSMinHeight);
    height: var(--buttonSMinHeight);
  }
  &.Xs {
    width: var(--buttonXsMinHeight);
    height: var(--buttonXsMinHeight);
  }
  &.Xxs {
    width: var(--buttonXxsMinHeight);
    height: var(--buttonXxsMinHeight);
  }
}

.chips {
  background: var(--buttonChipsColor);
  border-color: var(--buttonChipsColor);
  border-radius: 100px;
  &:hover {
    background: var(--buttonChipsColorHover);
    border-color: var(--buttonChipsColorHover);
  }
  &.isActive {
    background: var(--buttonChipsColorActive);
    border-color: var(--buttonChipsColorActive);
    color: var(--buttonChipsTextActive);
  }
}

.scaleUp {
  transition: buttons.$transition;
  &:hover {
    transform: buttons.$transformScaleUpHover;
  }
  &:active {
    transform: buttons.$transformScaleUpActive;
  }
}

.scaleDown {
  transition: buttons.$transition;
  &:hover {
    transform: buttons.$transformScaleDownHover;
  }
  &:active {
    transform: buttons.$transformScaleDownActive;
  }
}

.lightenUp {
  &.blue {
    &:hover {
      --buttonBackgroundColor: #{colors.$blueLightenUp};
      --buttonBorderColor: #{colors.$blueLightenUp};
    }
  }
  &.white {
    &:hover {
      --buttonBackgroundColor: #{colors.$whiteLightenUp};
      --buttonBorderColor: #{colors.$whiteLightenUp};
    }
  }

  &.black {
    &:hover {
      --buttonBackgroundColor: #{colors.$blackLightenUp};
      --buttonBorderColor: #{colors.$blackLightenUp};
    }
  }

  &.red {
    &:hover {
      --buttonBackgroundColor: #{colors.$redLightenUp};
      --buttonBorderColor: #{colors.$redLightenUp};
    }
  }

  &.green {
    &:hover {
      --buttonBackgroundColor: #{colors.$greenLightenUp};
      --buttonBorderColor: #{colors.$greenLightenUp};
    }
  }
}

.lightenDown {
  &.blue {
    &:hover {
      --buttonBackgroundColor: #{colors.$blueLightenDown};
      --buttonBorderColor: #{colors.$blueLightenDown};
    }
  }
  &.white {
    &:hover {
      --buttonBackgroundColor: #{colors.$whiteLightenDown};
      --buttonBorderColor: #{colors.$whiteLightenDown};
    }
  }

  &.black {
    &:hover {
      --buttonBackgroundColor: #{colors.$blackLightenDown};
      --buttonBorderColor: #{colors.$blackLightenDown};
    }
  }

  &.red {
    &:hover {
      --buttonBackgroundColor: #{colors.$redLightenDown};
      --buttonBorderColor: #{colors.$redLightenDown};
    }
  }

  &.green {
    &:hover {
      --buttonBackgroundColor: #{colors.$greenLightenDown};
      --buttonBorderColor: #{colors.$greenLightenDown};
    }
  }
}
