@use '@op/ui/src/styles/mixins/pxToVw.module' as pxToVw;
@use '@op/ui/src/styles/mixins/medias.module' as media;
@use '@op/ui/src/consts/theme/editor.module' as editorModule;
@use '@op/ui/src/consts/theme/colors.module' as colors;
@use '@op/ui/src/consts/theme/fonts.module' as fonts;

.body {
  border-radius: 20px;
  padding: 16px 24px;
  background: colors.$whiteT_100;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(197, 210, 216, 1);
}

.title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  padding-left: 35px;
  font-family: fonts.$fontBase;
  font-size: 24px;
  line-height: 1.34;
  font-weight: 700;
  @include media.media(mobile) {
    padding-left: 0;
    line-height: 1.17;
  }
}

.titleIcon {
  position: absolute;
  width: 18px;
  height: 24px;
  top: 3px;
  left: 0;
  color: currentColor;

  @include media.media(mobile) {
    display: none;
  }
}

.row {
  display: flex;
  margin-bottom: 9px;

  @include media.media(mobile) {
    flex-wrap: wrap;
    margin-bottom: 2px;
  }
}

.rowItem {
  flex-grow: 1;
  margin: 0 20px 0 0 !important;
  font-family: fonts.$fontBase;
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;

  @include media.media(mobile) {
    font-size: 12px;
    line-height: 1.4;
  }

  span {
    font-weight: 400;
    font-family: fonts.$fontBase;
    font-size: 16px;
    line-height: 1.25;

    @include media.media(mobile) {
      font-size: 12px;
      line-height: 1.4;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.rowBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media.media(mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.sum {
  font-family: fonts.$fontBase;
  font-size: 40px;
  line-height: 1.25;
  font-weight: bold;
  @include media.media(mobile) {
    margin-bottom: 2px;
  }
}
