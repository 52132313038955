@keyframes arrowAngleHover {
  48% {
    transform: translate(100%, -100%);
  }
  49% {
    transform: translate(-100%, 100%);
    animation-duration: 0s;
  }
  50% {
    transform: translate(-100%, 100%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes arrowHover {
  48% {
    transform: translate(100%, 0);
  }
  49% {
    transform: translate(-100%, 0);
    animation-duration: 0s;
  }
  50% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

$scaleUpHover: 1.05;
$scaleUpActive: 0.9;
$scaleDownHover: 0.9;
$scaleDownActive: 1.05;
$lightenUpHoverBlue: #3d73ff;
$transformScaleUpHover: scale(#{$scaleUpHover});
$transformScaleUpActive: scale(#{$scaleUpActive});
$transformScaleDownHover: scale(#{$scaleDownHover});
$transformScaleDownActive: scale(#{$scaleDownActive});
$defaultDisplay: inline-flex;
$shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
$transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
$animationArrowAngle: arrowAngleHover 0.6s cubic-bezier(0.58, -0.3, 0.64, 1.18) forwards;
$animationArrow: arrowHover 0.6s cubic-bezier(0.58, -0.3, 0.64, 1.18) forwards;

:export {
  baseTransition: $transition;
  defaultColor: blue;
  defaultDisplay: $defaultDisplay;
  shadow: $shadow;
  scaleUpHover: $scaleUpHover;
  scaleUpActive: $scaleUpActive;
  transformScaleUpHover: $transformScaleUpHover;
  transformScaleUpActive: $transformScaleUpActive;
  transformScaleDownHover: $transformScaleDownHover;
  transformScaleDownActive: $transformScaleDownActive;
  scaleDownHover: $scaleDownHover;
  scaleDownActive: $scaleDownActive;
  lightenUpHoverBlue: $lightenUpHoverBlue;
  animationArrowAngle: $animationArrowAngle;
  animationArrow: $animationArrow;
}
